<template>
  <div class="">
    <topnav></topnav>
    <div class="page-wrap blog-page">
      <h1
        v-if="$lang === 'en'"
        class="blog-title serif loading"
      >{{ blog.title }}</h1>
      <h1
        v-else-if="$lang === 'fr'"
        class="blog-title serif"
      >{{ blog.titleFrench || blog.title }}</h1>

      <div class="blog-img"><img :src="blog.image.imageURL" />
      </div>

      <p
        v-if="$lang === 'en'"
        class="blog-img-txt serif"
      >{{ blog.title }}</p>
      <p
        v-else-if="$lang === 'fr'"
        class="blog-img-txt serif"
      >{{ blog.titleFrench || blog.title }}</p>

      <div class="blog-wrap">
        <!-- <div class="blog-side-wrap">
          <p
            v-if="$lang === 'en'"
            class="blog-quote serif"
          >{{ blog.quote }}</p>
          <p
            v-else-if="$lang === 'fr'"
            class="blog-quote serif"
          >{{ blog.quoteFrench || blog.quote }}</p>

          <router-link
            to="/form"
            class="blog-cta"
          >
            <p><b>
                {{ $t('ready to sell?') }}</b>
              {{ $t('start here by uploading photos of your jewels and filling our interactive form.') }}
            </p>
            <img src="../assets/arrow.svg" />
          </router-link>
        </div> -->
        <div class="toc">
          <div>
            <p
              class="toc-title"
              v-if="$lang === 'en'"
            >Table of Contents</p>
            <p
              class="toc-title"
              v-else-if="$lang === 'fr'"
            >Table des matières</p>
            <router-link
              class="table-link"
              :to="`#${item.id}`"
              v-for="item in contentTable"
              :key="item.id"
              v-html="item.innerText"
            >
            </router-link>
          </div>
        </div>
        <div class="blog-art-wrap">
          <div
            v-if="$lang === 'en'"
            class="blog-body"
            v-html="blog.body"
          />
          <div
            v-else-if="$lang === 'fr'"
            class="blog-body"
            v-html="blog.bodyFrench || blog.body"
          />
          <social-buttons
            v-if="$lang === 'en'"
            :title="blog.title"
          ></social-buttons>
          <social-buttons
            v-else-if="$lang === 'fr'"
            :title="blog.titleFrench || blog.title"
          ></social-buttons>

        </div>
      </div>

      <div class="blog-ctrl">
        <router-link
          v-if="prev"
          :to="`/blog/${prev._id}`"
          class="blog-ctrl-link"
        >
          <div class="blog-ctrl-link-img-wrap"><img
              src="../assets/arrow.svg"
              class="blog-ctrl-arrow"
            />
            <img
              :src="prev.image.imageURL"
              class="blog-ctrl-img"
            />
            <div class="blog-ctrl-color"></div>
          </div>
          <div class="blog-ctrl-txt-wrap">
            <p
              v-if="$lang === 'en'"
              class="blog-ctrl-title serif"
            >{{ prev.title }}</p>
            <p
              v-else-if="$lang === 'fr'"
              class="blog-ctrl-title serif"
            >{{ prev.titleFrench || prev.title }}</p>

            <p
              v-if="prev.date"
              class="blog-ctrl-date"
            >{{ new Date(prev.date).toDateString('en-US') }}</p>
          </div>
        </router-link>
        <router-link
          v-if="next"
          :to="`/blog/${next._id}`"
          class="blog-ctrl-link next"
        >
          <div class="blog-ctrl-link-img-wrap"><img
              src="../assets/arrow.svg"
              class="blog-ctrl-arrow"
            />
            <img
              :src="next.image.imageURL"
              class="blog-ctrl-img"
            />
            <div class="blog-ctrl-color"></div>
          </div>
          <div class="blog-ctrl-txt-wrap">
            <p
              v-if="$lang === 'en'"
              class="blog-ctrl-title serif"
            >{{ next.title }}</p>
            <p
              v-else-if="$lang === 'fr'"
              class="blog-ctrl-title serif"
            >{{ next.titleFrench || next.title }}</p>
            <p
              v-if="next.date"
              class="blog-ctrl-date"
            >{{ new Date(next.date).toDateString('en-US') }}</p>
          </div>
        </router-link>
      </div>
    </div>
    <botfooter></botfooter>
  </div>
</template>

<script>
import Botfooter from '@/components/Botfooter.vue';
import Topnav from '@/components/Topnav.vue';
import SocialButtons from '../components/SocialButtons.vue';
import { blog } from '@/data/localData.json'
export default {
  components: {
    Topnav,
    Botfooter,
    SocialButtons
  },
  name: "Blog",
  data() {
    return {
      next: null,
      prev: null,
      titlePattern: /<h[23].*>(.*?)<\/h[23]>/g
    }
  },
  metaInfo() {
    return {
      ssrAppId: 'clarity',
      ...this.seo({
        title: this.$lang === 'fr' ? this.blog.titleFrench || this.blog.title : this.blog.title,
        image: this.blog.image.imageURL,
        description: this.$lang === 'fr' ? this.blog.blurbFrench : this.blog.blurb
      }),
    };
  },
  methods: {
    setNext(id) {
      const reveseBlog = [...blog].reverse()
      const index = reveseBlog.findIndex(b => b._id === id)
      this.next = reveseBlog[index + 1] || null
      this.prev = reveseBlog[index - 1] || null
    },
  },
  beforeRouteUpdate(to, _, next) {
    this.setNext(to.params.id)
    next()
  },
  computed: {
    blog() {
      return blog.find(b => b._id === this.$route.params.id);
    },
    contentTable() {
      return this.$lang === 'en' ? this.blog.contentTable : this.blog.contentTableFrench;
    },
  },
  mounted() {
    this.setNext(this.$route.params.id)
  }
};
</script>