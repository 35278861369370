<template>
 <div class="blog-share-box">
            <a
              :href="facebook"
              class="blog-share-link"
            >
              <img src="../assets/Facebook-small.svg" /></a>
            <a
              :href="twitter"
              class="blog-share-link"
            >
              <img src="../assets/twitter-small.svg" /></a>
            <a
              :href="email"
              class="blog-share-link"
            >
              <img src="../assets/mail-small.svg" /></a>
            <a
              :href="whatsapp"
              class="blog-share-link"
            >
              <img src="../assets/link-small.svg" /></a>
          </div>
</template>

<script>
export default {
  props: { title: String, classList: String, size: Number, color: String },
  computed: {
    fill() {
      return this.color || "black";
    },
    path() {
      const baseURL = window.location.origin;
      const { id } = this.$route.params;
      const path = `${baseURL}/event/${id}`;
      return path;
    },
    whatsapp() {
      let str = `https://api.whatsapp.com/send?text=${this.path}\n${this.title}`;
      return encodeURI(str);
    },
    facebook() {
      let str = `https://www.facebook.com/sharer.php?u=${this.path}&t=${this.title}`;
      return encodeURI(str);
    },
    linkedIn() {
      const str = `https://www.linkedin.com/shareArticle?url=${this.path}&title=${this.title}`;
      return str;
    },
    twitter() {
      let str = `https://twitter.com/intent/tweet?url=${this.path}&text=${this.title}`;
      return encodeURI(str);
    },
    email() {
      let str = `mailto:?subject=${this.title}&body=${this.path} \n${this.title}`;
      return encodeURI(str);
    },
  },
};
</script>

<style>
.soc-link-bgl {
  margin-bottom: 15px;
  background-color: #2d3034;
  border-radius: 100%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.soc-link-bgl:hover {
  background: #5c6168;
}
.soc-wrap {
  display: flex;
  flex-direction: column;
}
.soc-icon-bgl {
}
</style>
